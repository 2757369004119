<script setup lang="ts">
import { IconButton } from '@laam/ui/base';
import { PhHandbag } from '@phosphor-icons/vue';
import { useCartStore } from '~/stores/cart';

import { Popover, PopoverContent, PopoverTrigger } from '@laam/ui/base';
const Cart = defineAsyncComponent(() => import('./cart/index.vue'));

const CartStore = useCartStore();
const route = useRoute();
const { pdpRedesignExperiment } = useStatsigExperiment();
const pathName = computed(() => route.path);
</script>
<template>
	<NuxtLink
		v-if="isMobileView() && pathName !== '/cart'"
		class="cart_trigger_mobile relative flex items-center"
		:to="'/cart'"
	>
		<IconButton
			as="div"
			size="nav-button"
			variant="icon"
			:class="{
				'!p-0':
					pdpRedesignExperiment?.showSizeForm &&
					route.path.includes('products'),
			}"
		>
			<PhHandbag />
		</IconButton>
		<span
			v-if="CartStore.getItemsCount !== 0"
			class="right-xs top-4xl h-2xl w-4xl text-xxs absolute font-semibold text-center text-white bg-red-500 rounded-full"
			:class="{
				'!top-2xl !-right-xs':
					pdpRedesignExperiment?.showSizeForm &&
					route.path.includes('products'),
			}"
		>
			{{ CartStore.getItemsCount }}
		</span>
	</NuxtLink>

	<div v-else-if="pathName !== '/cart'">
		<Popover>
			<PopoverTrigger class="cart_trigger_desktop relative">
				<slot name="default" :item-count="CartStore.getItemsCount">
					<IconButton
						size="nav-button"
						variant="icon"
						data-testid="cart-button"
					>
						<PhHandbag />
					</IconButton>
					<span
						v-if="CartStore.getItemsCount !== 0"
						class="right-xs top-4xl h-2xl w-4xl text-xxs absolute font-semibold text-center text-white bg-red-500 rounded-full"
					>
						{{ CartStore.getItemsCount }}
					</span>
				</slot>
			</PopoverTrigger>
			<PopoverContent
				class="w-[520px] !rounded-xl z-[50] overflow-hidden"
				align="end"
			>
				<div class="py-3xl px-7xl bg-white border-b">
					<span
						class="block w-full text-xl font-semibold text-center text-gray-800"
					>
						Shopping Bag
					</span>
				</div>

				<div>
					<Cart />
				</div>
			</PopoverContent>
		</Popover>
	</div>
</template>
